.categoryEdit {
  background: #ffffff;
  padding: 29px;
  position: relative;

  .x {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .label {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    display: block;
    color: #000000;
    margin-bottom: 0;
    margin-top: 10px;
  }

  .input,
  .textarea {
    box-sizing: border-box;
    border-radius: 3px;
    padding: 10px;
    width: 100%;
  }

  .dropdown {
    border: 1px solid #a3a3a3 !important;
    width: 100%;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 7px;
    display: flex;
    justify-content: space-between;
    background-color: #ffffff !important;
    color: black !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;

    .category {
      margin-right: 5px;
      padding: 3px 5px;
      background: #ffc1c1;
      width: fit-content;
    }

    .tag {
      margin-right: 5px;
      width: fit-content;
      padding: 3px 5px;
      background: #fff7b2;
    }
  }

  .tags {
    width: 100%;
  }
}

.categoryTag {
  padding: 1px 3px;
  background: #ffc1c1;
  text-align: center;
  width: fit-content;
  margin: 1px 1px 1px 0px;
  font-size: 12px;
  border-radius: 4px;
  text-transform: capitalize;
  letter-spacing: -0.5px;
}

.searchInput {
  background: transparent;
  border: none;
  border-bottom: 1px solid #dadada;
  width: 100%;
  font-size: 16px;
  margin-left: 6px;
  padding: 5px 5px;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
}

.searchInput:focus {
  border-bottom-width: 2px;
  outline: none;
}
