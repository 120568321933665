.notesParent {
  font-size: 80%;

  .list-group {
    .list-group-item {
      padding: 0.5rem 1rem;
      white-space: pre-line;

      .delete-icon {
        cursor: pointer !important;
        font-size: 110%;
      }
    }
  }

  textarea {
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
    line-height: 1.2;
    min-height: 50px;
    width: calc(100% - 43px);
    border: 1px solid lightgray;

    &:focus {
      border: 1px solid gray;
    }
  }
}
