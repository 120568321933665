.imgWrap {
  width: 125px;
  height: 100px;
  background-color: #eee;
  margin-right: 12px;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 25%);
  flex-shrink: 0;
  @media only screen and (max-width: 786px) {
    width: 80px;
    height: 64px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.fulfillmentWrap {
  :global {
    .delivery_pending,
    .delivery_in_progress,
    .pickup_pending {
      color: #ec8686 !important;
    }

    .delivery_failed,
    .delivery_cancelled,
    .pickup_failed {
      color: gray !important;
    }
  }
}
