.photo {
  position: relative;
  margin-top: 16px;
  &:hover .actions {
    opacity: 1;
  }

  .actions {
    position: absolute;
    text-align: center;
    margin: 0 auto;
    display: inline-block;
    top: 10px;
    right: 25px;
    justify-content: center;

    opacity: 1;
    transition: all 0.5s ease;

    button {
      background: transparent;
      outline: none;
      border: none;
    }

    i {
      color: #a1a1a1;

      &:hover {
        color: #505050;
      }
    }
  }
}

.mediaGrid {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
  column-gap: 0.5rem;
  margin-top: 1rem;
}

.zoomImage {
  position: relative;
  width: 100%; /* Responsive width */
  padding-top: 80%; /* Aspect ratio -> Height / Width = 4 / 5 = 0.8 = 80% */
  overflow: hidden; /* Ensures no overflow of content */
  box-shadow: 0px 4px 8px rgba(60, 74, 73, 0.5);
  border-radius: 8px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Full width of the container */
    height: 100%; /* Full height of the container */
    object-fit: cover; /* This ensures the image covers the full container */
  }
}

.media {
  position: relative;
  width: 100%; /* Responsive width */
  padding-top: 80%; /* Aspect ratio -> Height / Width = 4 / 5 = 0.8 = 80% */
  overflow: hidden; /* Ensures no overflow of content */
  box-shadow: 0px 4px 8px rgba(60, 74, 73, 0.5);
  border-radius: 8px;
  margin-bottom: 0.5rem;

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
    width: 100%;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  img.dragging {
    opacity: 0.5;
  }

  .actions {
    position: absolute;
    text-align: center;
    margin: 0 auto;
    display: inline-block;
    top: 2px;
    right: 2px;
    justify-content: center;
    opacity: 1;
    transition: all 0.5s ease;

    button {
      background: transparent;
      outline: none;
      border: none;
    }

    i {
      color: #a1a1a1;

      &:hover {
        color: #505050;
      }
    }
  }
}
