@import url("https://fonts.googleapis.com/css2?family=Cabin+Sketch:wght@700&display=swap");

.container {
  width: 100%;
  display: grid;
  place-items: center;

  .box {
    min-height: 70vh;
    height: fit-content;
    width: 45vw;
    border-radius: 30px;
    overflow: hidden;
    margin-top: 20px;

    .top {
      height: 30%;
      background-color: #ff8084;
      color: white;
      min-height: 150px;

      h1 {
        font-family: "Cabin Sketch", cursive;
        color: white;
        text-align: center;
        font-size: 80px;
        padding-top: 20px;
      }
    }

    .bottom {
      height: 70%;
      min-height: 300px;
      background-color: white;
      border-radius: 30px;
      margin-top: -30px;
      box-shadow: 1px 2px 3.5px #eee;
      padding: 40px 10%;

      input {
        height: 50px;
        margin-top: 20px;
        margin-bottom: 30px;
      }

      button {
        width: 100%;
        background-color: #ff8084;
        color: white;
        height: 50px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        border-radius: 30px;
        margin-top: 20px;

        &:focus,
        &:hover {
          outline: none;
          transform: scale(1.01);
        }
      }

      div.info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        @media (max-width: 700px) {
          flex-direction: column;
          align-items: flex-start;

          p {
            font-size: 15px !important;
            margin-bottom: 0px !important;
          }
        }

        p {
          font-size: 16px;
          color: black;
          opacity: 0.9;
        }
      }

      div.quotes {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 10px;
        margin: 10px 0px 30px 0px;

        div.quote {
          border-radius: 10px;
          padding: 10px 2px;
          text-align: center;
          background: #13c9ca;
          box-shadow: 1px 1.5px 3.5px #eee;

          p {
            color: white;
            font-size: 15px;
          }

          h2 {
            font-size: 25px;
            color: white;
          }
        }

        @media (max-width: 700px) {
          grid-template-columns: 1fr;
          grid-column-gap: 0px;

          .quote {
            margin: 5px 0px;
          }
        }
      }

      @media (max-width: 700px) {
        padding: 20px 5%;
      }
    }

    @media (max-width: 700px) {
      width: 95%;
      min-height: 90vh;
      height: fit-content;

      .top {
        min-height: 120px;
        h1 {
          font-size: 50px;
        }
      }
    }
  }
}
