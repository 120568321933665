.select-search-container {
  flex: 1;
}

.select-search-input {
  height: 36px;
  padding: 0.5em;
  border: none;
  border-left: 1px solid rgb(221, 221, 221);
  border-radius: 4px;
  width: 100%;
}

.select-search-select {
  position: absolute;
  z-index: 9;
  border-radius: 6px;
  background: white;
  width: 200px;

  ul.select-search-options {
    li button {
      border: none;
      outline: none;
      width: 200px;
      padding: 8px 5px;
      text-align: left;
      font-weight: 600;
      color: darkslategray;
      background: transparent;
      border-bottom: 1px solid #eee;
    }
  }
}
