/* App.css */
.messagesContainer {
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fullHeightCard {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.messageInput {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #f8f9fa;
}

.messageInput textarea {
  flex-grow: 1;
  margin-right: 10px;
}

.conversationContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 50vh;
  overflow-y: auto;
  padding: 10px;
}

.conversation {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  border-top: 1px solid lightgray;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.conversation .message {
  margin-bottom: 10px;
}

.messagesList {
  height: 75vh;
  overflow-y: scroll;
}

.messageContentContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
}

.messageName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 3;
}

.messageDate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  flex: 1;
}

.messageContent {
  width: 100%;
  height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification {
  border: 1.5px solid;
  padding: 10px;
  font-weight: 600;
  border-radius: 8px;
  font-size: 1rem;
  line-height: 1.5;

  &.warning {
    color: #ae7633;
    border-color: #ae7633;
    background-color: #fbf4ec;
  }
}

.text {
  color: #3c4a49;
  font-size: 1rem;
  margin-bottom: 0px;
  margin-top: 6px;
  font-weight: 500;

  &.light {
    color: #627470;
  }
}

.input {
  height: 40px;
  border: 1px solid #bac5c5;
  width: 100%;
  border-radius: 8px;
  margin-top: 8px;
}
