@import 'assets/scss/app.scss';

* {
    font-family: Nunito;
}

.w-full {
    width: 100%;
}

.infinite-scroll-component__outerdiv {
    width: 100%
}

.csvUploadBox {
    height: 200px;
    width: 400px;
    background: #f3f3f3;
    border: 1px dashed #dadada;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width:500px) {
        width: 300px;
    }
}

.uploadBtn {
    width: 400px;
    background-color: #007bff !important;
    cursor: pointer;
    height: 40px;

    @media (max-width:500px) {
        width: 300px;
    }
}

.fs-18 {
    font-size: 18px;
}

.fs-16 {
    font-size: 16px;
}

.fs-14 {
    font-size: 14px;
}

.c-primary {
    color: #007bff;
}

.error {
    color: rgb(243, 20, 20);
    font-weight: 600;
    font-size: 15px;
    margin-top: 5px;
}

.DateRangePicker {
    background: white;
    box-shadow: 1px 2px 6px #dadada;
    border-radius: 4px;
    position: absolute !important;
    z-index: 99999;
    top: 0px;
}

input.react-dropdown-select-input {
    font-size: 15px !important;
    letter-spacing: -0.5px;
}
