.avatar {
    height: 180px;
    width: 180px;
    padding: 5px;
    border: 1.5px solid #dadada;
    border-radius: 100%;
    object-fit: cover;
}

.detailItem {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #eee;
    padding: 8px 10px;
    border-radius: 6px;
    margin-top: 10px;
    box-shadow: 1px 2px 3px #eee;
    position: relative;

    .status {
        position: absolute;
        top: 5px;
        right: 8px;
        color: #0dad0d;
        font-weight: 700;
        font-size: 13px;
        letter-spacing: -0.5px;
        cursor: pointer;
    }

    div.icon {
        height: 40px;
        width: 40px;
        display: grid;
        place-items: center;
        border-radius: 100%;

        svg {
            color: white;
            height: 20px;
        }
    }

    .detail {
        h4 {
            margin-bottom: 0px !important;
            font-size: 17px;
            margin-top: 5px;
            font-weight: 600;
        }

        p {
            color: black;
            margin-bottom: 0px;
            font-size: 16px;
            opacity: 0.9;
        }
    }
}

h2.header {
    font-size: 25px;
    width: 100%;
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
}
