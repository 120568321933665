.title {
  color: black;
  font-weight: 700;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-size: 15px;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.location {
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.chip {
  position: absolute;
  top: -35px;
  right: 0px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 2px 0px 0px 2px;
}

.money {
  font-size: 17px;
  font-weight: 700;
  color: darkslategray;
  margin-bottom: 0px;
}

.miles {
  color: #28a745;
  font-weight: 700;
}

.donor {
  color: darkslategray;
  font-weight: 700;
  opacity: 0.5;
  font-style: italic;
  font-size: 13px;
  letter-spacing: -0.5px;
  max-width: 70px;
  overflow: hidden;
}

.donorRetailer {
  background-color: #b81717;
  color: white;
  border-radius: 0.5rem;
  padding: 0.1rem;
}

td.field {
  font-style: italic;
}

table.errortable {
  width: 100%;
  text-align: right;
}

.ribbon {
  width: 150px;
  position: absolute;
  padding: 3px 5px;
  text-align: center;
  color: #f0f0f0;
  top: 25px;
  left: -32px;
  z-index: 1;
  transform: rotate(-45deg);
  box-shadow: 1px 2px 12px rgb(103, 103, 103);
  background-color: #17a2b8;
  font-weight: 600;
  letter-spacing: -0.1px;
  color: white;
  &.active {
    background: #17a2b8 !important;
  }
  &.inactive {
    background: #b86d17 !important;
  }
  &.donated {
    background: #17b82c !important;
  }
  &.expired {
    background: #b81717 !important;
  }
}

.outofstockOverlay {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  background: #00000031;
  top: 0px;
  border-radius: 8px;
  pointer-events: none;
}

.outofstockOverlay::before {
  content: "OUT OF STOCK";
  color: white;
  margin-top: 100px;
  padding-top: 100px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: block;
  font-weight: 700;
  font-size: 15px;
  text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.906);
}

.pill {
  background-color: #e3eceb;
  padding: 3px 8px 3px;
  border-radius: 16px;
  color: #212e2b;
  font-weight: 600;
  font-size: 0.85rem;
  line-height: 18px;
  text-transform: capitalize;
  white-space: nowrap;
  max-height: 25px;
  margin-bottom: 5px;

  &.pink {
    background: #f9e5eb;
  }
}

.imageContainer {
  position: relative;
  width: 100%; /* Responsive width */
  padding-top: 80%; /* Aspect ratio -> Height / Width = 4 / 5 = 0.8 = 80% */
  overflow: hidden; /* Ensures no overflow of content */
  box-shadow: 0px 4px 8px rgba(60, 74, 73, 0.1);

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Full width of the container */
    height: 100%; /* Full height of the container */
    object-fit: cover; /* This ensures the image covers the full container */
  }
}
