.blogEditor {
  @import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

  h1 {
    font-family: Lato, sans-serif;
    color: black;
    text-transform: none;
    line-height: 2.25rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    span {
      font-size: 2.25rem;
      font-weight: 500;
      color: black;
    }
  }

  h2 {
    font-family: Lato, sans-serif;
    color: black;
    text-transform: none;
    line-height: 2.25rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    span {
      font-size: 1.875rem;
      font-weight: 500;
      color: black;
    }
  }

  h3 {
    font-family: Lato, sans-serif;
    color: black;
    text-transform: none;
    line-height: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    span {
      font-size: 1.5rem;
      font-weight: 500;
      color: black;
    }
  }

  h4 {
    font-family: Lato, sans-serif;
    color: black;
    text-transform: none;
    line-height: 1.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    span {
      font-size: 1.25rem;
      font-weight: 500;
      color: black;
    }
  }

  h5 {
    font-family: Lato, sans-serif;
    color: black;
    text-transform: none;
    line-height: 1.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    span {
      font-size: 1.125rem;
      font-weight: 500;
      color: black;
    }
  }

  h6 {
    font-family: Lato, sans-serif;
    color: black;
    text-transform: none;
    line-height: 26px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    span {
      font-size: 16px;
      font-weight: 500;
      color: black;
    }
  }

  p {
    font-family: Lato, sans-serif;
    color: black;
    text-transform: none;
    line-height: 1.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    span {
      font-size: 1.125rem;
      font-weight: 500;
      color: black;
    }

    a > span {
      font-size: 1.125rem;
      font-weight: 500;
      color: rgb(11 101 104);
      text-decoration-line: underline;
    }

    strong {
      font-size: 1.125rem;
      font-weight: bolder;
      color: black;
    }

    em {
      font-style: italic;
      font-size: 1.125rem;
      color: black;
    }
  }

  ul {
    font-family: Lato, sans-serif;
    li {
      font-size: 1.125rem;
      font-weight: 500;
      color: black;
    }
  }

  ol {
    font-family: Lato, sans-serif;
    li {
      font-size: 1.125rem;
      font-weight: 500;
      color: black;
    }
  }

  blockquote {
    font-family: Lato, sans-serif;
    color: black;
    text-transform: none;
    line-height: 1.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-inline-start: 2em;
    border-left-width: 4px;
    border-left-color: rgb(229, 231, 235);
    border-left-style: solid;
    span {
      font-size: 1.125rem;
      font-weight: 500;
      color: black;
    }
  }
}
