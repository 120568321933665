.listing {
    color: #ffffff;
    background-color: #7fbfff;
}

.listing-inverse {
    color: #7fbfff;
}

.order {
    color: #ffffff;
    background-color: #bf3f7f;
}

.order-inverse {
    color: #bf3f7f;
}

.item {
    color: #ffffff;
    background-color: #7fbf00;
}

.item-inverse {
    color: #7fbf00;
}

.money {
    color: #ffffff;
    background-color: #3f7f3f;
}

.money-inverse {
    color: #3f7f3f;
}

.turnover48h {
    color: #ffffff;
    background-color: #ffbf3f;
}

.turnover48h-inverse {
    color: #ffbf3f;
}

.turnoverp10 {
    color: #ffffff;
    background-color: #ff7f00;
}

.turnoverp10-inverse {
    color: #ff7f00;
}

.turnoverp50 {
    color: #ffffff;
    background-color: #7f3f1f;
}

.turnoverp50-inverse {
    color: #7f3f1f;
}

.dashboard__card {
    min-height: 220px;
    min-width: 300px;
    background: white;
    border-radius: 4px;
    padding: 15px;
    letter-spacing: 0.5px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-shadow: 1px 2px 6px rgba(238, 238, 238, 0.747);

    h3 {
        font-weight: 600;
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: -0.5px;
        color: black;
        opacity: 0.9;
        text-align: left;
        width: 100%;
    }

    .float-right {
        margin-top: 30px;

        p.subTitle {
            color: rgb(143, 143, 143);
            font-weight: 500 !important;
            font-size: 15px;
            opacity: 0.9;
            letter-spacing: -0.5px;
            text-transform: capitalize;
        }

        h1.count {
            font-size: 50px !important;
            line-height: 130%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 10px;

            span.svg {
                background: #40e0d02e;
                border-radius: 100%;
                height: 70px;
                width: 70px;
                min-height: 70px;
                min-width: 70px;
                display: grid;
                place-items: center;
            }

            span.ml-3{
                letter-spacing: -5px;
            }
        }
    }
}
