.dropdown {
  border: 1px solid #a3a3a3 !important;
  width: 100%;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 7px;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff !important;
  color: black !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}
