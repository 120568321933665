.searchLocationInputContainer {
  position: relative;
}

.searchLocationInput {
  width: 100%;
  padding-right: 20px; /* make space for the clear button */
}

.searchLocationInputClearBtn {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #ccc;
}

.searchLocationInput:focus + .searchLocationInputClearBtn {
  color: #000;
}
