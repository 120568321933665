$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": #0b6568,
    "secondary": #6c757d,
    "success": #28a745,
    "danger": #dc3545,
    "warning": #ffc107,
    "info": #17a2b8,
    "light": #f8f9fa,
    "dark": #343a40,
  ),
  $theme-colors
);

@function variant-color($variant) {
  @return map-get($theme-colors, $variant);
}

$color--track: #cee0e1;
$color--disabled: #e9e9e9;
// $color--focus: #54616e40;
$color--focus: rgba(84, 97, 110, 0.251);
$color--tooltip-bg: black;
$color--tooltip-fg: white;

input[type="range"].itemsSlider {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0;
  line-height: 1.5;
  font: 1rem/1 arial, sans-serif;
  color: $color--track;
  background-clip: padding-box;
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  position: relative;

  &::-moz-focus-outer {
    outline: none;
    border: none;
  }

  &::-moz-focusring {
    outline: none;
  }

  &.itemsSlider--sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0;
    font-size: 0.875rem;
  }

  &.itemsSlider--lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 0;
    font-size: 1.25rem;
  }

  // Track

  @mixin track {
    box-sizing: border-box;
    border: none;
    height: 8px;
    background: $color--track;
    border-radius: 5px;
  }

  &::-webkit-slider-runnable-track {
    @include track;
  }
  &::-moz-range-track {
    @include track;
  }
  &::-ms-track {
    @include track;
  }
  &::-ms-fill-lower {
    @include track;
  }

  &::-ms-track {
    color: transparent;
  }

  @mixin track--lg {
    height: 6px;
    border-radius: 3px;
  }

  &.itemsSlider--lg::-webkit-slider-runnable-track {
    @include track--lg;
  }
  &.itemsSlider--lg::-moz-range-track {
    @include track--lg;
  }
  &.itemsSlider--lg::-ms-track {
    @include track--lg;
  }
  &.itemsSlider--lg::-ms-fill-lower {
    @include track--lg;
  }

  @mixin track--disabled {
    background: $color--disabled;
    cursor: not-allowed;
  }

  &.disabled::-webkit-slider-runnable-track {
    @include track--disabled;
  }
  &.disabled::-moz-range-track {
    @include track--disabled;
  }
  &.disabled::-ms-track {
    @include track--disabled;
  }

  // Thumb

  @mixin thumb {
    -webkit-appearance: none;
    box-sizing: border-box;
    border: none;
    border-radius: 50%;
    background: variant-color("primary"); // default
    height: 30px;
    width: 30px;
    cursor: pointer;
    transition: box-shadow 0.5s ease;
  }

  @each $name, $color in $theme-colors {
    &.itemsSlider--#{$name} {
      &::-webkit-slider-thumb {
        background: $color;
      }
      &::-moz-range-thumb {
        background: $color;
      }
      &::-ms-thumb {
        background: $color;
      }

      &:not(.disabled) {
        &:focus,
        &:active {
          &::-webkit-slider-thumb {
            box-shadow: 0 0 0 0.2rem rgba($color, 0.25);
          }
          &::-moz-range-thumb {
            box-shadow: 0 0 0 0.2rem rgba($color, 0.25);
          }
          &::-ms-thumb {
            box-shadow: 0 0 0 0.2rem rgba($color, 0.25);
          }
        }
      }
    }
  }

  &::-webkit-slider-thumb {
    @include thumb;
    margin-top: -11px;
  }
  &::-moz-range-thumb {
    @include thumb;
  }
  &::-ms-thumb {
    @include thumb;
    margin-top: 0;
  }

  @mixin thumb--sm {
    height: 16px;
    width: 16px;
  }

  &.itemsSlider--sm::-webkit-slider-thumb {
    @include thumb--sm;
    margin-top: -6px;
  }
  &.itemsSlider--sm::-moz-range-thumb {
    @include thumb--sm;
  }
  &.itemsSlider--sm::-ms-thumb {
    @include thumb--sm;
    margin-top: 0;
  }

  @mixin thumb--lg {
    height: 24px;
    width: 24px;
  }

  &.itemsSlider--lg::-webkit-slider-thumb {
    @include thumb--lg;
    margin-top: -9px;
  }
  &.itemsSlider--lg::-moz-range-thumb {
    @include thumb--lg;
  }
  &.itemsSlider--lg::-ms-thumb {
    @include thumb--lg;
    margin-top: 0;
  }

  @mixin thumb--disabled {
    background: $color--disabled;
    cursor: not-allowed;
  }

  &.disabled::-webkit-slider-thumb {
    @include thumb--disabled;
  }
  &.disabled::-moz-range-thumb {
    @include thumb--disabled;
  }
  &.disabled::-ms-thumb {
    @include thumb--disabled;
  }

  // MS tooltip
  &::-ms-tooltip {
    display: none;
  }
}

.itemsSlider__wrap {
  position: relative;
  display: block;
  height: calc(1.5em + 0.75rem + 2px);

  // Tooltip

  .itemsSlider__tooltip {
    display: block;
    position: absolute;
    width: 0;
    height: 26px;
    text-align: center;
    overflow: visible;
    opacity: 0;
    transition: opacity 0.15s linear;
    z-index: 1070;

    &.itemsSlider__tooltip--top {
      bottom: calc(0.75em + 0.375rem + 1px + 0.4rem + 10px + 3px);
      // bottom: 0;
      top: auto;
    }

    &.itemsSlider__tooltip--bottom {
      top: calc(0.75em + 0.375rem + 1px + 0.4rem + 10px + 3px);
      // top: 0;
      bottom: auto;
    }

    &.itemsSlider__tooltip--on {
      opacity: 0.9;
    }

    &.itemsSlider__tooltip--on.itemsSlider__tooltip--disabled {
      opacity: 0.25;
    }

    .itemsSlider__tooltip__label {
      position: absolute;
      transform: translateX(-50%);
      max-width: 200px;
      padding: 0 0.5rem;
      height: 26px;
      line-height: 26px;
      color: $color--tooltip-fg;
      text-align: center;
      background-color: $color--tooltip-bg;
      opacity: 0.9;
      border-radius: 0.25rem;
      box-sizing: border-box;
      font-size: 0.875rem;
      white-space: nowrap;
    }

    .itemsSlider__tooltip__caret {
      position: absolute;
      transform: translateX(-50%);
      display: block;
      width: 0.8rem;
      height: 0.4rem;
      opacity: 0.9;

      &::before {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid;
        left: 0;
      }
    }

    &.itemsSlider__tooltip--top .itemsSlider__tooltip__caret {
      bottom: -0.4rem;

      &::before {
        top: 0;
        border-width: 0.4rem 0.4rem 0;
        border-top-color: $color--tooltip-bg;
      }
    }

    &.itemsSlider__tooltip--bottom .itemsSlider__tooltip__caret {
      top: -0.4rem;

      &::before {
        bottom: 0;
        border-width: 0 0.4rem 0.4rem;
        border-bottom-color: $color--tooltip-bg;
      }
    }
  }

  &.itemsSlider__wrap--sm {
    height: calc(1.5em + 0.5rem + 2px);
    font-size: 0.875rem;

    .itemsSlider__tooltip--top {
      bottom: calc(0.75em + 0.25rem + 1px + 0.4rem + 8px + 3px);
    }

    .itemsSlider__tooltip--bottom {
      top: calc(0.75em + 0.25rem + 1px + 0.4rem + 8px + 3px);
    }
  }

  &.itemsSlider__wrap--lg {
    height: calc(1.5em + 1rem + 2px);
    font-size: 1.25rem;

    .itemsSlider__tooltip--top {
      bottom: calc(0.75em + 0.5rem + 1px + 0.4rem + 12px + 3px);
    }

    .itemsSlider__tooltip--bottom {
      top: calc(0.75em + 0.5rem + 1px + 0.4rem + 12px + 3px);
    }
  }

  &:hover {
    .itemsSlider__tooltip:not(.itemsSlider__tooltip--off):not(
        .itemsSlider__tooltip--disabled
      ) {
      opacity: 0.9;
    }

    .itemsSlider__tooltip--disabled:not(.itemsSlider__tooltip--off) {
      opacity: 0.25;
    }
  }
}
