$placeholderColor: gray;

.dropdown {
  border: 1px solid #90a5a1;
  border-radius: 8px;
  background-color: #fcfcfc !important;
  color: #3c4a49;
  min-height: 53px;
  width: 100%;
  margin-bottom: 0px;
  background: #fcfcfc;
  font-size: 15px;
  padding-left: 10px;
  overflow-y: hidden;

  p {
    padding: 8px 35px 8px 8px;
    background: #ffc1c1;
    border-radius: 4px;
    color: black !important;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: -0.5px;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 53px;
    margin-right: 10px;
    margin-bottom: 4px;
  }

  span {
    font-size: 20px;
    color: $placeholderColor;
    text-transform: none !important;
    font-weight: 400;
  }

  &::placeholder {
    color: $placeholderColor !important;
  }
}

.dropdown.error {
  border: 1px solid rgb(239, 1, 1) !important;
  background: #fd9b9b0f !important;
}

.menu {
  width: 100%;
  box-shadow: 0px 4px 14px rgba(60, 72, 72, 0.1);
  border: 1px solid #efefef;
  border-radius: 8px;
  max-height: 400px;
  overflow-y: auto;

  h4 {
    font-weight: 700;
    font-size: 20px;
    color: #212e2b;
    margin-top: 8px;
  }

  p {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 16px;
    color: #212e2b;
    padding: 6px 0px;
    cursor: pointer;
    margin-top: 6px;
  }

  p.parent {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: #0b6568;
    width: 100%;
    border-bottom: 1px solid #dadada;
    padding: 0px;
    cursor: unset;
    margin-top: 10px;
  }
}

.dropdownItem button {
  background-color: yellow;
}

.dropdownItem:hover {
  background-color: #ffc1c1;
  color: black;
}

.dropdownItem:active {
  background-color: #ffc1c1;
}
